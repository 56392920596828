import React, { useState, useContext, useRef } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";
const Pagination = ({ weektype, Paginationdata }) => {
  const numbersWrapperRef = useRef(null);
  const [activePage, setActivePage] = useState(
    Paginationdata?.currentPage || 1
  );

  const { getWeekResultAT, getWeekResultLW, getWeekResultTW } =
    useContext(LeaderboardContext);

  const handleClick = (pageNumber) => {
    // fet data for next page
    if (weektype === "Previous") {
      // fet data for last week
      getWeekResultLW(pageNumber);
      setActivePage(pageNumber);
    } else if (weektype === "current") {
      // fet data for current week
      getWeekResultTW(pageNumber);
      setActivePage(pageNumber);
    } else if (weektype === "alltime") {
      // fet data for all
      getWeekResultAT(pageNumber);
      setActivePage(pageNumber);
    } else {
      return null;
    }
  };
  const totalPages = Paginationdata?.totalPages || 1;

  const scrollHandler = (direction) => {
    if (direction === "left") {
      numbersWrapperRef.current.scrollLeft -= 100;
    } else if (direction === "right") {
      numbersWrapperRef.current.scrollLeft += 100;
    }
  };

  if (totalPages <= 1) return null;

  return (
    <div
      ref={numbersWrapperRef}
      className={
        totalPages >= 13
          ? "pagination-element-wrapper scroll-active"
          : "pagination-element-wrapper"
      }
    >
      <button
        onClick={() => scrollHandler("left")}
        className="pagination-scroll-btn pagination-left"
      >
        &#60;
      </button>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          className={`pagination-element ${
            activePage === i + 1 ? "active" : ""
          }`}
          onClick={() => handleClick(i + 1)}
        >
          {i + 1}
        </button>
      ))}
      <button
        onClick={() => scrollHandler("right")}
        className="pagination-scroll-btn pagination-right"
      >
        &#62;
      </button>
    </div>
  );
};

export default Pagination;
