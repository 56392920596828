import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.scss";

import LeaderboardProvider from "./provider/leaderboard";
import "react-toastify/dist/ReactToastify.css";
// Pages
import LeaderBoard from "./pages/leaderboard";
import NavBar from "./components/NavBar/NavBar";
import Error from "./pages/ErrorPage/Error";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiProvider } from "wagmi";
import { mainnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const projectId = "4fd1ca99d364ee964d86ea44323c2a05";
if (!projectId) {
  throw new Error("VITE_PROJECT_ID is not set");
}

// 2. Create wagmiConfig
const wagmiConfig = defaultWagmiConfig({
  chains: [mainnet],
  projectId,
  metadata: {
    name: "",
    description: "",
    url: "",
    icons: [],
  },

  // Edit the metadata to match the project
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  themeMode: "dark",
  themeVariables: {
    "--w3m-color-mix": "#231517",
    "--w3m-color-mix-strength": 20,
  },
});

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <LeaderboardProvider>
          <ToastContainer />
          <NavBar />
          <Routes>
            <Route path="/*" element={<Error />} />
            <Route path="/" element={<LeaderBoard />} />
          </Routes>
        </LeaderboardProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
