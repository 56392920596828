import { useState, useEffect, useContext } from "react";
import { LeaderboardContext } from "../../provider/leaderboard";

function HourCountdownTimer() {
  const { roundInfo } = useContext(LeaderboardContext);
  const [lastSeconds, setLastSeconds] = useState("");
  const [socketdata, setSocketData] = useState({
    created: new Date(),
    duration: 0,
  });

  const dataLoaded = roundInfo?.created && roundInfo?.duration;

  useEffect(() => {
    if (dataLoaded) {
      setSocketData(roundInfo);
    }
  }, [roundInfo, dataLoaded]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeline = new Date(socketdata.created);
      timeline.setSeconds(timeline.getSeconds() + socketdata.duration);

      const diffMs = timeline - new Date();
      if (diffMs <= 0) {
        clearInterval(intervalId);
        if (dataLoaded) {
          console.log("Data loaded and timer completed");
          // If data is loaded and timer completed, reload the page
          window.location.reload();
        }
        // setLastSeconds("Time's up!");
        return;
      }

      const hour = Math.floor((diffMs / (1000 * 60 * 60)) % 24);
      const min = Math.floor((diffMs / (1000 * 60)) % 60);
      const sec = Math.floor((diffMs / 1000) % 60);

      const timeString = `${hour} H : ${min} M : ${sec} S`;
      setLastSeconds(timeString);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [socketdata]);

  return (
    <div>
      <h2>Countdown to Next Hour</h2>
      <p>{dataLoaded ? lastSeconds : "Loading..."}</p>
    </div>
  );
}

export default HourCountdownTimer;
