import { useState, useContext, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

import gsap from "gsap";

import "./index.scss";

// Assets
import { ReactComponent as InfoIcon } from "../../assets/media/icons/InfoIcon.svg";

import { ReactComponent as CloseIcon } from "../../assets/media/icons/CancelIcon.svg";
const Header = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const [showFAQmodal, setShowFAQmodal] = useState(false);
  const [showFullETHCount, setShowFullETHCount] = useState(false);

  const tl = gsap.timeline();

  // Closing the modal with the escape key
  const handleEscape = (event) => {
    if (event.code === "Escape" && showFAQmodal) {
      modalCloseHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });

  const modalClickHandler = () => {
    setShowFAQmodal(true);

    setTimeout(() => {
      tl.to(".faqmodal", { opacity: 1 })
        .to(
          ".faqmodal",
          {
            width: isSmallDevice ? "100%" : "35rem",
            ease: "power4.out",
          },
          "-=0.3"
        )
        .to(".faqmodal-header", { opacity: 1 }, "-=0.3")
        .from(".question", { y: 20, opacity: 0, stagger: 0.1 }, "-=0.3");
    }, 1);
  };

  const modalCloseHandler = () => {
    tl.to(".faqmodal", { duration: 0.3, opacity: 0 })
      .to(".faqmodal-back", { duration: 0.3, opacity: 0 })
      .then(() => {
        setShowFAQmodal(false);
      });
  };

  return (
    <>
      {showFAQmodal && (
        <>
          <div
            onClick={(e) => {
              modalCloseHandler();
              handleEscape(e);
            }}
            className="faqmodal-back"
          />
          <div className="faqmodal">
            <div className="faqmodal-header">
              <div className="faqmodal-header-title">Rules and FAQs</div>
              <div
                onClick={(e) => {
                  modalCloseHandler();
                  handleEscape(e);
                }}
                className="faqmodal-header-close"
              >
                <CloseIcon />
              </div>
            </div>
            <div className="faqmodal-content">
              <div className="question">
                <div className="faqmodal-content-title">
                  1. How do I play/participate in the game?
                </div>
                <p>
                  Simply connect your Metamask wallet to the P&L tracker game
                  website to be enrolled for the weekly game.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  2. What is being calculated?
                </div>
                <p>
                  We are summing all the realized losses of the connected wallet
                  for NFT sales made in ETH.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  3. Do we need ETH/USD to play the game?
                </div>
                <p>
                  The simple answer is no! All you need is a Metamask wallet to
                  connect to the website.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  4. How do I win the game and get on the allowlist?
                </div>
                <p>
                  Each week the top 100 wallets with the most realized losses
                  (trading NFTs) would win the game and get an allowlist spot.
                  Make sure to connect the wallet with the most accumulated
                  losses to increase your chances. This is our way of giving a
                  small win to the people who have booked the most losses
                  trading NFTs on the secondary market.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  5. Why am I unable to play the game?
                </div>
                <p>
                  A user can win only one allowlist spot. If you’ve won in any
                  of the weeks, you would not be able to play the game further
                  and would be granted 1x allowlist spot. You can search for
                  your wallet on the ‘All-Time’ leaderboard.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  6. In case I don’t win, do I automatically get enrolled for
                  next week's game?
                </div>
                <p>
                  No. You would be required to connect to the website again in
                  case you lose and wish to participate again using the same
                  wallet.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  7. I booked more losses recently but the stats haven’t been
                  updated on the website?
                </div>
                <p>
                  We do not ping the participating wallets to update the stats
                  automatically. If in case you wish to refresh the stats since
                  you have booked more losses on secondary purchases, you would
                  need to connect the wallet again to the website. Also, unless
                  OS is unable to catch the mint price of the tokens, all sales
                  would be considered profits.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  8. I recently booked some profits trading NFTs. Do they impact
                  my realized losses?
                </div>
                <p>
                  The realized losses are not set off by profits since the game
                  is not based on net profit/loss. The released losses remain
                  the same even if you have booked profits on some other
                  token/s.
                </p>
              </div>

              <div className="question">
                <div className="faqmodal-content-title">
                  9. Which ERC20 tokens are being considered for the game?
                </div>
                <p>
                  We are only tracking ETH NFT buys and sells made either with
                  ETH or WETH. All other alt ERC20 tokens are not being
                  considered for calculating the realized losses (ex: USDC, ASH,
                  APE, etc.)
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="header">
        <div onClick={modalClickHandler} className="header-left-side">
          <InfoIcon />
          <div className="trading-rules">Rules and FAQ</div>
        </div>
      </div>
    </>
  );
};

export default Header;
