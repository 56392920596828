import { useEffect, useState } from "react";
import gsap from "gsap";

// Styles
import "./Notification.scss";

// Assets
import BearLaugh from "../../assets/media/icons/laughing-bear.png";
import TwitterIcon from "../../assets/media/icons/TwitterPixel.png";
import { TwitterShareButton } from "react-share";

const Notification = ({ userLost, setIsNotificationOpen }) => {
  const imageUrl = "/thumbnail.png";

  const [imagePasteInfo, setImagePasteInfo] = useState(false);

  useEffect(() => {
    const tl = gsap.timeline({
      defaults: { ease: "back.inOut", duration: 0.5 },
    });

    tl.to(".user-notification-popup-wrapper", { opacity: 1, delay: 0.5 })
      .to(".user-notification-popup", { width: "37rem", opacity: 1 })
      .to(
        [
          ".congrats-message",
          ".copy-btn",
          ".notification-popup-buttons",
          ".image-paste-info",
        ],
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
        }
      );
  }, []);

  const closeModalHandler = () => {
    gsap.to([".user-notification-popup-wrapper", ".user-notification-popup"], {
      opacity: 0,
      stagger: 0.25,
      duration: 1,
      onComplete: () => {
        setIsNotificationOpen(false);
      },
    });
  };

  const copyImageToClipboard = async () => {
    setImagePasteInfo(true);
    try {
      // Fetch the image
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create an off-screen canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Create an image and draw it on the canvas
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = URL.createObjectURL(blob);
      img.onload = async () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Convert the canvas to a Blob
        canvas.toBlob(async (blob) => {
          // Copy the Blob to the clipboard
          await navigator.clipboard.write([
            new ClipboardItem({ "image/png": blob }),
          ]);
        }, "image/png");
      };
    } catch (error) {
      console.error("Failed to copy image: ", error);
    }
  };

  // Closing the modal with the escape key
  const handleEscape = (event) => {
    if (event.code === "Escape") {
      closeModalHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });

  return (
    <>
      <div
        onClick={closeModalHandler}
        className="user-notification-popup-wrapper"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="user-notification-popup"
        >
          <p className="congrats-message">
            Congratulations, you lost{" "}
            <span className="eth-count">{userLost.toFixed(2)}</span> ETH!{" "}
            <img src={BearLaugh} alt="Bear laughing" draggable="false" />
          </p>
          <button onClick={copyImageToClipboard} className="copy-btn">
            {imagePasteInfo ? "Copied" : "Copy Image"}
          </button>
          <div className="notification-popup-buttons">
            <TwitterShareButton
              url="https://bearandbull.game/"
              title={`I lost ${userLost} ETH in the Bear and Bull game. I'm not upset about it because it's a win 🐻`}
              hashtags={["BearAndBull", "CryptoGame", "ETH"]}
              className="notification-share-btn"
            >
              <img
                src={TwitterIcon}
                alt="The icon of the Twitter share button"
              />
              <span>Share on Twitter/X</span>
            </TwitterShareButton>

            <div onClick={closeModalHandler} className="okay-button">
              Close
            </div>
          </div>
        </div>

        <p className="image-paste-info">
          {imagePasteInfo
            ? "Successfully copied. Now click on ‘Share on Twitter’ button and paste the image on the tweet before posting."
            : "Click the ‘Copy Image’ button to copy the image to your clipboard."}
        </p>
      </div>
    </>
  );
};

export default Notification;
