import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { useWeb3Modal } from "@web3modal/wagmi/react";

// Components
import Footer from "../../components/Footer/Footer";

// Assets
import SageBear from "../../assets/media/images/SageBear.gif";
import SageBearBubble from "../../assets/media/images/SageBearDialogue.png";
import { WalletContext } from "../../context/Wallet";

const PLTrackerNoWallet = () => {
  const isSmallDevice = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const { open } = useWeb3Modal();

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (localStorage?.getItem("isWalletConnected") === "true") {
        try {
          await activate(injected);
          localStorage.setItem("isWalletConnected", true);
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();
  }, []);

  return (
    <>
      <div className="nowallet">
        <div className="connectyourwallet-wrapper">
          <div className="connectyourwallet-box">
            <div className="connectyourwallet-left">
              <img src={SageBear} className="sagebear-gif" alt="" />
            </div>
            <div className="connectyourwallet-right">
              <div className="connectyourwallet-right-top">
                <img src={SageBearBubble} className="sagebear-bubble" alt="" />
              </div>
              <div
                onClick={open}
                className="connectyourwallet-right-bottom login-button"
              >
                CONNECT YOUR WALLET {!isSmallDevice && "TO JOIN LEADERBOARD"}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PLTrackerNoWallet;
