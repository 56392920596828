import { http } from "../services";
export const thisweekResult = (queryData) => {
  return http.get("/users/thisweek", {
    params: queryData,
  });
};
export const ApiLastWeekResult = (queryData) => {
  return http.get("/users/lastweek", {
    params: queryData,
  });
};
export const AlltimeResult = (queryData) => {
  return http.get("/users/alltime", { params: queryData });
};
