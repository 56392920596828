import { useState, useContext, useEffect } from "react";
import { TopWinnerItem } from "./topWinnerItem.jsx";
import { LeaderboardContext } from "../../provider/leaderboard";
import Pagination from "../../pages/leaderboard/Pagination";
import "./index.scss";

const TopWinner = ({ result, weektype, loading }) => {
  const { searchInput, setAlreadyWon, alreadyWon } =
    useContext(LeaderboardContext);
  const [sortedTop, setSortedTop] = useState([]);
  const [topResult, setTopResult] = useState([]);

  useEffect(() => {
    if (result?.all_time_users) {
      const sortedUsers = [...result.all_time_users].sort(
        (a, b) => a.lose - b.lose
      );
      // Adding an absolute rank to each user
      sortedUsers.forEach((user, index) => {
        user.absoluteRank = index + 1;
      });
      setSortedTop(sortedUsers);
    }
  }, [result]);

  useEffect(() => {
    setTopResult(sortedTop);
  }, [sortedTop]);

  useEffect(() => {
    if (searchInput) {
      const filtered = sortedTop.filter((res) => {
        if (res.ens) {
          return res.ens.toLowerCase().includes(searchInput.toLowerCase());
        }
        return res.walletAddress
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setTopResult(filtered);
    } else {
      setTopResult(sortedTop);
    }
  }, [searchInput, sortedTop]);

  return (
    <>
      {!loading ? (
        topResult?.length > 0 ? (
          topResult.map((res, index) => {
            const userrank =
              res.absoluteRank +
              (result?.pagination?.currentPage - 1) *
                result?.pagination?.itemsPerPage;
            return (
              <div key={res?.walletAddress}>
                <TopWinnerItem
                  index={userrank}
                  leaderboard={res}
                  setAlreadyWon={setAlreadyWon}
                  alreadyWon={alreadyWon}
                  loading={loading}
                />
              </div>
            );
          })
        ) : (
          <div className="no-result">
            <p>No result found...</p>
          </div>
        )
      ) : (
        <div className="no-result">
          <p>Loading...</p>
        </div>
      )}
      {topResult?.length > 0 && !loading && (
        <Pagination weektype={weektype} Paginationdata={result?.pagination} />
      )}
    </>
  );
};

export default TopWinner;
